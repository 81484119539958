export const LIST_INSTRUCTIONS_GAIN = [
  {
    icon: 'HomeOutline',
    exp: 1,
    message: 'Acesso diário'
  },
  {
    icon: 'Question',
    exp: 2,
    message: 'Fazer exercício dos Cadernos'
  },
  {
    icon: 'QuestionFull',
    exp: 2,
    message: 'Gabaritar exercício dos Cadernos'
  },
  {
    icon: 'LikedDoubt',
    exp: 2,
    message: 'A cada “Gostei” na dúvida que você enviou'
  },
  {
    icon: 'RedactionIcon',
    exp: '1',
    message: 'A cada 100 pontos na nota da redação'
  }
];

export const LIST_INSTRUCTIONS_LOSS = [
  {
    icon: 'DislikedDoubt',
    exp: 1,
    message: 'A cada “Não gostei” na dúvida que você enviou'
  }
];
