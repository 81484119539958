






























































import { Component, Vue, Prop } from 'vue-property-decorator';

import ExpIcon from '@/assets/icons/Exp.vue';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import AvatarRanking from '@/components/AvatarRanking/index.vue';

import LevelsLoading from './LevelsLoading.vue';

import GamificationService from '@/services/Gamification/gamificationService';

import { RankingData, Level } from '@/globalInterfaces/Gamification';

@Component({
  components: {
    ExpIcon,
    BoxContainer,
    LevelsLoading,
    AvatarRanking
  }
})
export default class Levels extends Vue {
  @Prop({ default: null }) userRanking!: RankingData | null;
  @Prop({ default: false }) loading!: boolean;

  private isLoadingLevels = true;

  private levels: Level[] = [];

  private GamificationService = new GamificationService();

  created() {
    this.getLevels();
  }

  async getLevels() {
    try {
      this.isLoadingLevels = true;

      const levels = await this.GamificationService.getLevels();

      this.levels = levels;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a listagem dos níveis',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoadingLevels = false;
    }
  }
}
