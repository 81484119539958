var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BoxContainer',{staticClass:"instructions"},[_c('h2',{staticClass:"instructions__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"instructions__wrapper-points"},_vm._l((_vm.instructions),function(instruction,index){return _c('div',{key:index,staticClass:"points"},[_c('div',{staticClass:"points__icon",style:({
          borderColor: _vm.isGain
            ? _vm.$colors['primarycolor-pure']
            : _vm.$colors['feedbackcolor-error-medium'],
        })},[_c(instruction.icon,{tag:"component",class:{
            '--icon-question-full': instruction.icon === 'QuestionFull',
            '--icon-question': instruction.icon === 'Question',
          },attrs:{"fill":_vm.isGain ? undefined : _vm.$colors['feedbackcolor-error-medium']}})],1),_c('div',{staticClass:"points__exp"},[_c('h4',{staticClass:"points__title"},[_vm._v(_vm._s(_vm.isGain ? '' : '-')+_vm._s(instruction.exp))]),_c('ExpIcon')],1),_c('p',{staticClass:"points__message"},[_vm._v(" "+_vm._s(instruction.message)+" ")])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }